.structures {
  padding: 0;
  margin: 100px auto;

  &__content {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
  }
}
