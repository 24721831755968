.sponsorship {
  position: relative;
  margin: 250px auto 100px auto;
  padding: 0;

  &__position {
    position: absolute;
    top: 20%;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 6;

    & svg {
      position: absolute;
      font-size: 30px;
      right: 0;
      top: 0;
    }
  }

  &__title {
    color: #74767b;
    text-align: center;
    font-size: 30px;
    margin-bottom: 40px;
    font-weight: bold;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  &__payme {
    margin-top: 50px;
    display: flex;
    align-items: center;
    gap: 50px;

    &-logo {
      width: 200px;
      object-fit: cover;
      cursor: pointer;
    }
  }

  &__form {
    width: 500px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & input {
      width: 100%;
      background: #f5f5f5;
      border: none;
      padding: 20px;
      margin-bottom: 15px;
      font-size: 18px;
      margin-top: auto;
      border-radius: 6px;

      &::placeholder {
        font-size: 18px;
        color: #74767b;
      }
    }

    & button {
      width: 60%;
      background: #da251c;
      border: none;
      padding: 18px;
      margin-bottom: 15px;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
      border-radius: 6px;
    }
  }
}

.position {
  position: absolute;
  right: 0;
  bottom: 20%;
  z-index: -10;
  opacity: 0.1;
  width: 500px;
  height: 500px;

  & img {
    width: 100%;
    height: 100%;
  }
}

.sponsorship__btn {
  width: 100%;
  display: flex;
  justify-content: center;

  & p {
    padding: 25px 35px;
    background: #f5f5f5;
    cursor: pointer;
    width: 500px;
    display: flex;
    justify-content: center;
    font-weight: bold;
    color: #0b99ff;
    font-size: 30px;
  }
}

.sponsorship__payment {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.sponsorship__payment_price {
  padding: 25px 35px;
  background: #f5f5f5;
  cursor: pointer;
  font-weight: 600;
}

.sponsorship__payment_price_active {
  background: #da251c;
  color: #fff;
  cursor: pointer;
}

.image-radio {
  display: flex;
  align-items: center;

  & input {
    margin-right: 10px;
  }
}
