.container-history {
  width: 100%;
  margin: 250px auto;
}

.block_container {
  margin: 250px auto;
}

.container {
  display: none;
}

@media (min-width: 1280px) {
  .container {
    display: block;
  }
}

.mt-5 {
  margin-top: 50px;
}

.ab-history {
  background-color: white;
}

.grid {
  display: grid;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.font-medium {
  font-weight: 500;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-slate-500 {
  color: #6b7280;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.top-0 {
  top: 0;
}

.bottom-64 {
  bottom: 16rem;
}

.left-1\/2 {
  left: 50%;
}

.transform {
  transform: translateX(-50%);
}

.-translate-x-1\/2 {
  transform: translateX(-50%);
}

.w-1\.5 {
  width: 0.375rem;
}

.justify-center {
  justify-content: center;
}

.z-0 {
  z-index: 0;
}

.bg-card-blue {
  background-color: #ff6d6d;
}

.sticky {
  position: sticky;
}

.top-28 {
  top: 7rem;
}

.z-10 {
  z-index: 10;
}

.w-full {
  width: 100%;
}

.h-60 {
  height: 15rem;
}

.bg-slate-300 {
  background-color: #dbd1d1;
}

.h-full {
  height: 100%;
}

.tabs:nth-child(odd) {
  align-self: flex-end;
}

.tabs:nth-child(odd):before,
.tabs:nth-child(odd):after {
  right: unset !important;
  left: 0 !important;
  transform: translate(-50%, -50%) !important;
}

.tabs.filled:after {
  background: #ff5f5f;
  border: 6px solid #fff;
}

.tabs:before {
  background: #f2f7fa !important;
}

.tabs.filled:before {
  opacity: 1 !important;
  transition: 0.3s;
}

.tabs.filled .desc {
  opacity: 1 !important;
}

.container {
  display: none;
}

@media (min-width: 1280px) {
  .container {
    display: block;
  }
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.top-0 {
  top: 0;
}

.bottom-64 {
  bottom: 16rem;
}

.left-1\/2 {
  left: 50%;
}

.transform {
  transform: translateX(-50%);
}

.-translate-x-1\/2 {
  transform: translateX(-50%);
}

.w-1\.5 {
  width: 0.375rem;
}

.justify-center {
  justify-content: center;
}

.z-0 {
  z-index: 0;
}

.bg-card-blue {
  background-color: #ff6d6d;
}

.sticky {
  position: sticky;
}

.top-28 {
  top: 7rem;
}

.z-10 {
  z-index: 10;
}

.w-full {
  width: 100%;
}

.h-60 {
  height: 15rem;
}

.bg-slate-300 {
  background-color: #dbd1d1;
}

.h-full {
  height: 100%;
}

.z-0 {
  z-index: 0;
}

.timelinepoint {
  height: 18rem;
  width: 50%;
  position: relative;
  padding-left: 5rem;
  padding-right: 5rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  transition: 0.3s;
}

.tabs::after {
  content: "";
  position: absolute;
  width: 3.5rem;
  height: 3.5rem;
  top: 0;
  right: 0;
  background-color: #dbd1d1;
  border-radius: 9999px;
  border-width: 6px;
  border-color: #ffffff;
  transform: translateX(50%) translateY(-50%);
  z-index: 2;
}

.tabs::before {
  content: "";
  position: absolute;
  width: 5rem;
  height: 5rem;
  top: 0;
  right: 0;
  background-color: white;
  border-radius: 9999px;
  border-width: 4px;
  border-color: blue;
  transform: translateX(50%) translateY(-50%);
  opacity: 0;
}

.opacity-50 {
  opacity: 0.5;
}

.text-7xl {
  font-size: 4.5rem;
  /* 72px */
  line-height: 1;
}

.font-medium {
  font-weight: 500;
}

.text-blue-500 {
  color: rgb(255, 0, 0);
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

@media (max-width: 1100px) {
  .m1100 {
    display: block;
  }
  .m11002 {
    display: none;
  }
}

@media (min-width: 1100px) {
  .m1100 {
    display: none;
  }
}

.m1100 p {
  text-align: justify;
}
