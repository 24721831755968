.container {
  margin: 250px auto;
  padding: 20px;
}

.full {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.w80 {
  width: 77%;
}

.w20 {
  width: 23%;
}

.dFlex {
  display: flex;
  gap: 20px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
}

.w70 {
  width: 70%;
}

.w30 {
  width: 30%;
}

.userPic {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

.cardBody {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;

  .item {
    display: flex;
    align-items: center;

    span {
      width: 30%;
      font-weight: bold;
      font-size: 16px;
      color: #555;
    }

    p {
      width: 70%;
      font-size: 16px;
      font-weight: 500;
      color: #777;
      margin: 0;
    }
  }
}

.title {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin: 0 0 10px 0;
}

.additionalTitle {
  font-size: 24px;
  font-weight: bold;
  color: #444;
  margin-top: 20px;
}

.about {
  margin-top: 20px;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.menu {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;

  .title {
    padding: 10px;
    text-align: center;
    background-color: #c10b01;
    font-size: 25px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .item {
    .items {
      display: flex;
      flex-direction: column;
      font-size: 20px;
      font-weight: 500;
      padding: 15px 10px;
      border-bottom: 1px solid #ababab;
      color: #494949;
      border-radius: 5px;
      margin: 10px 0;

      &:hover {
        background-color: #e14038;
        color: #fff;
        transition: background-color 0.3s, color 0.3s;
      }
    }
  }
}
