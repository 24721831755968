.cart {
  position: relative;
  padding: 0 15px;
  margin: 250px auto 100px auto;

  &__info {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    & button {
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      background: #da251c;
      width: 210px;
      line-height: 50px;
      border: none;
      cursor: pointer;
      text-decoration: none;
    }
  }
}

.cart__payment {
  position: fixed;
  background: rgba(245, 245, 245, 0.9);
  z-index: 20;
  top: 0;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  width: 100vw;
  height: 100vh;

  .payment-container {
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    background: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

    &__item {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      button {
        border: none;
        font-size: 30px;
        background: none;
        cursor: pointer;
      }
    }
  }
}

.payment-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__field {
    display: flex;
    flex-direction: column;
    gap: 5px;

    label {
      font-size: 16px;
      font-weight: bold;
    }

    input {
      padding: 10px;
      font-size: 16px;
      border: 1px solid #ddd;
      border-radius: 4px;
    }
  }

  &__submit {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #da251c;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 30;

  .modal-content {
    background: #fff;
    padding: 50px;
    border-radius: 8px;
    position: relative;
    max-width: 400px;
    width: 100%;
    max-height: 400px;
    height: 100%;

    .modal-close {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 24px;
      background: none;
      border: none;
      cursor: pointer;
    }
  }
}

.modal-images {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  & img {
    cursor: pointer;
    width: 150px;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  & label {
    // height: 300px;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;

    & input {
      margin-top: 10px;
    }
  }
}
