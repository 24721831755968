.container {
  padding-left: 0;
  padding-right: 0;
  margin: 50px auto !important;
}

.title {
  margin-top: 25px;
  margin-bottom: 25px;
}

.contact_container {
  display: flex;
  flex-direction: column;
}

.contact_page_wrapper {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.contact_page_wrapper > .main_content {
  flex: 1;
}

.territory_info {
  min-width: 500px;
  width: 100%;
  max-height: 300px;
  flex: 1;
  padding: 25px;
  border-radius: 16px;
  background: rgb(240, 240, 240);
  overflow: auto;
}

.territorial_divisions_map > svg path {
  fill: rgb(122, 120, 120);
  transition: 0.5s;
}

.territorial_divisions_map > svg path:hover {
  cursor: pointer;
  fill: #da251c;
}

.active {
  fill: black;
}

.territorial_divisions_map > svg {
  margin-top: 20px;
  width: 80%;
}

.territorial_divisions_map {
  flex: 1.5;
  display: flex;
  justify-content: center;
}

.territorial_divisions_deputy_title {
  font-size: 28px;
  font-weight: bold;
}

.territorial_divisions_deputy_text {
  font-size: 20px;
  font-weight: 500;
}

.email {
  margin: 22px 0;
}

@media (max-width: 940px) {
  .contact_wrapper {
    padding: 30px;
  }
}

@media (max-width: 450px) {
  .contact_page_wrapper {
    flex-direction: column;
    gap: 30px;
  }

  .territory_info {
    width: 100%;
  }
}
