.menu {
  .title {
    padding: 10px;
    text-align: center;
    background-color: #c10b01;
    font-size: 25px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    border-radius: 5px;
  }

  .item {
    .items {
      display: flex;
      flex-direction: column;
      font-size: 20px;
      font-weight: 500;
      padding: 15px 10px;
      border-bottom: 1px solid #ababab;
      color: #494949;
      border-radius: 5px;
      margin: 10px 0px;
      text-decoration: none;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: #e14038;
        color: #fff;
      }
    }
  }
}
