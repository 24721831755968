.statute {
  margin-top: 100px;
  padding: 0;

  &__content {
    margin: 100px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 80px;
  }
}
