.cartItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  padding-bottom: 20px;
  border-bottom: #74767b 1px solid;

  &:last-child {
    border-bottom: none;
  }

  &__img {
    width: 250px;
    height: 250px;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__name {
    color: #74767b;
    font-size: 36px;
    width: 500px;
    margin-left: 30px;
    text-align: start;
    font-weight: bold;
  }

  &__counter {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    & button {
      width: 45px;
      height: 45px;
      border-radius: 100%;
      border: none;
      cursor: pointer;
    }

    & p {
      font-size: 18px;
      text-align: center;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    width: 200px;
    justify-content: space-between;

    & button {
      width: 45px;
      height: 45px;
      border-radius: 100%;
      border: none;
      cursor: pointer;
    }

    & p {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
