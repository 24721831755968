.slider__block {
  position: relative;
  height: 80vh;
  z-index: 3;
  overflow: hidden; /* Prevent horizontal scroll */
}

.slider__items {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 1;

  & a {
    position: absolute;
    background: #da251c;
    cursor: pointer;
    padding: 25px;
    z-index: 10;
    left: 20%;
    bottom: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-size: 22px;
    color: #fff;
    font-weight: bold;
    transition: 0.5s;

    &:hover {
      text-shadow: 0 0 10px #fff;
    }
  }
}

.slider__items h1 {
  color: #fff;
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 100px;
  text-align: center;
  z-index: 2;
}

.slider__items::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background: linear-gradient(
    180deg,
    rgba(52, 116, 188, 0) 0%,
    rgba(52, 116, 188, 1) 100%
  );
  opacity: 1;
  z-index: 0;
}

.home-hero2-info {
  margin-top: auto;
  padding: 20px 50px 50px;
  background: linear-gradient(
    0deg,
    #003087d4,
    rgb(37 58 96 / 84%) 21.15%,
    rgba(0, 48, 135, 0.111589) 47.76%,
    rgba(0, 48, 135, 0) 78.2%
  );
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.slider__img {
  width: 100%;
  height: 900px;
  object-fit: cover;
}

.slider .swiper {
  height: 100vh;
}

.swiper {
  --swiper-theme-color: #fff;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: 0.5;
  width: 100%; /* Ensure swiper takes full width */
}

.swiper-wrapper {
  display: flex;
}

.swiper-slide {
  flex: 1 0 100%; /* Ensure slides take full width */
}

@media (max-width: 1336px) {
  .slider__items {
    & a {
      left: 40%;
    }
  }

  .slider__block {
    height: 50vh;
  }
}

@media (max-width: 800px) {
  .slider__block {
    height: 30vh;
  }

  .slider__inners {
    max-height: 600px;
    height: 100%;
  }

  .slider__items {
    & a {
      left: 30%;
    }

    & h1 {
      font-size: 32px;
    }
  }
}

@media (max-width: 500px) {
  .slider__items {
    & a {
      left: 15%;
    }
  }
}

.slider {
  & a {
    position: absolute;
    background: #da251c;
    cursor: pointer;
    padding: 25px;
    z-index: 10;
    left: 20%;
    bottom: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-size: 22px;
    color: #fff;
    font-weight: bold;
    transition: 0.5s;

    &:hover {
      text-shadow: 0 0 10px #fff;
    }
  }
}
