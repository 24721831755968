

.fixed-status {
  height: 150px;

  @media (max-width: 1600px) {
    height: 0;
  }
}

.tops {
  margin-top: 160px;
}

.header {
  &__links {
    color: #74767b;
    font-size: 18px;
    font-weight: 400;
  }

  &-content {
    background: #c10b01;
    display: flex;
    border-bottom: 8px solid #ff0000;
    align-items: center;

    &__top {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px 0;
      gap: 50px;

      &-form {
        display: flex;
        align-items: center;
        border: none;
        background: #fff;
        height: 40px;
        border-radius: 6px;
        width: 250px;

        & input {
          width: 88%;
          height: 100%;
          border: none;
          padding: 0 10px;
          color: #74767b;
          font-size: 16px;
          border-radius: 6px 0 0 6px;
          font-weight: normal;
          font-weight: 400;
        }

        & button {
          width: 22%;
          border-radius: 0 6px 6px 0;
          height: 100%;
          background: #da251c;
          border: none;
          cursor: pointer;

          & svg {
            color: #fff;
            font-size: 30px;
          }
        }
      }
    }

    &__settings {
      display: flex;
      align-items: center;
      gap: 30px;
    }

    &__icon {
      color: #fff;
      font-size: 30px;
    }

    &__social {
      display: flex;
      list-style: none;
      gap: 15px;

      &-icon {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        color: #74767b;
        border-radius: 100%;
        font-size: 20px;
        cursor: pointer;

        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &-items {
    background: #fff;
    padding: 15px 0;

    &__cart {
      position: relative;
      margin-right: 30px;

      & p {
        position: absolute;
        background: #da251c;
        color: #fff;
        top: -5px;
        right: -5px;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
      }
    }

    &__block {
      display: flex;
      align-items: center;
      padding: 0;
      justify-content: space-between;

      @media (max-width: 1800px) {
        padding: 0 50px;
      }

      @media (max-width: 350px) {
        padding: 0px;
      }
    }

    &__logo {
      width: 250px;
    }

    &__list {
      display: flex;
      align-items: center;
      list-style: none;
      gap: 50px;
    }

    &__link {
      border: none !important;
      background: none !important;
      font-size: 24px !important;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-weight: bold;
      color: #3474bc;
      text-decoration: none !important;
    }

    &__buttons {
      display: flex;
      align-items: center;

      &-btn {
        background: none;

        &_open {
          z-index: 100000;
        }
      }

      & a {
        background: #da251c;
        padding: 10px 20px;
        text-decoration: none;
        color: #fff;
        font-weight: 600;
        font-size: 20px;
        transition: 0.5s;

        &:hover {
          text-shadow: 0 0 10px #fff;
        }
      }

      & button {
        border: none;
        cursor: pointer;

        &:nth-child(1) {
          color: #3474bc;
          font-weight: bold;
          font-size: 24px;
          margin: 0 35px;
        }
      }
    }
  }
}

body.grayscale {
  filter: grayscale(100%);
}

.navbar {
  width: 100%;
  position: fixed;
  z-index: 9;
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  transition: top 0.3s ease;
}

.navbar.grayscale-navbar {
  top: 36px;
}

.navbar.scrolled-navbar {
  top: 0;
}

.dropdown-icon {
  transition: transform 0.4s ease;
  color: #3474bc;

  &.rotate {
    transform: rotate(180deg);
  }
}

.dropdown-icon-1 {
  transition: transform 0.4s ease;
  color: #74767b;

  &.rotate-1 {
    transform: rotate(180deg);
  }
}

@media (max-width: 600px) {
  .header-content__social {
    display: none;
  }
}

@media (max-width: 1600px) {
  header {
    position: relative !important;
  }
}

@media (max-width: 1330px) {
  .header-items__list {
    display: none;
  }
}

@media (max-width: 700px) {
  .header-items__logo {
    width: 160px;
  }

  .header-items__donate {
    display: none;
  }

  .header-items__sign {
    display: none;
  }
}

.header-items__sign {
  background: none;
}

.header-items__sign_k {
  font-weight: bold;
  font-size: 24px !important;
  background: none !important;
  color: #3474bc !important;
}

.none-h50 {
  margin-bottom: 160px;
}
