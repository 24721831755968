.row {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 10px;
  padding: 50px 0;
}

.d-flex {
  display: flex;
}

.container {
  margin-top: 250px;
}

.w-70 {
  width: 69%;
}

.w-30 {
  width: 29%;
}

.menu {
  & .title {
    padding: 10px;
    text-align: center;
    background-color: #c10b01;
    font-size: 25px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    border-radius: 5px;
  }

  & .item {
    & .items {
      display: flex;
      flex-direction: column;
      font-size: 20px;
      font-weight: 500;
      padding: 15px 10px;
      border-bottom: 1px solid #ababab;
      color: #494949;
      border-radius: 5px;
      margin: 10px 0px;

      &:hover {
        background-color: #e14038;
        color: #fff;
      }
    }
  }
}

.d-flex {
  display: flex;
}

.mb-3 {
  margin-bottom: 3rem;
}

@media (max-width: 1600px) {
  .container {
    margin-top: 50px;
  }
}

@media (max-width: 1200px) {
  .w-30 {
    display: none;
  }
  .w-70 {
    width: 100%;
  }
  .row {
    justify-content: center;
  }
}
