.block {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-top: 1px solid #74767b;
  border-bottom: 1px solid #74767b;
  padding: 100px 0;
  @media (max-width: 800px) {
    flex-direction: column;
  }
  &__left {
    width: 65%;
    display: flex;
    flex-direction: column;

    @media (max-width: 800px) {
      width: 100%;
    }

    &-location {
      flex-grow: 1;
      & label {
        font-size: 20px;
        font-weight: bold;
        color: #74767b;
        margin-bottom: 20px;
      }

      & p {
        color: #74767b;
        font-size: 18px;
        font-weight: 500;
        margin: 5px 0 20px 0;
      }
    }

    &-email {
      margin-top: 30px;

      @media (max-width: 800px) {
        margin-top: 0;
      }

      & label {
        font-size: 20px;
        font-weight: bold;
        color: #74767b;
        margin-bottom: 20px;
      }

      & p {
        color: #74767b;
        font-size: 18px;
        font-weight: 500;
        margin: 5px 0 20px 0;
      }
    }

    &-workMode {
      margin-top: 100px;

      @media (max-width: 800px) {
        margin-top: 10px;
        margin-bottom: 30px;
      }
      &_label {
        font-size: 20px;
        font-weight: bold;
        color: #74767b;
        margin-bottom: 20px;
      }

      &_items {
        display: flex;
        gap: 20px;
        padding-right: 20px;

        & div {
          & div {
            background: #da251c;
            padding: 10px;
            border-radius: 6px;
            text-align: center;
            margin-bottom: 10px;

            & p {
              color: #fff;
              font-size: 14px;
              line-height: 1.5;
              margin: 0;

              padding: 0;
              font-weight: 800;
            }
          }
          & p {
            color: #74767b;
            font-size: 14px;
            font-weight: 600;
          }
        }

        &-weak {
          background: none !important;
          border: 1px solid #da251c !important;

          & p {
            color: #da251c !important;
          }
        }
      }
    }
  }

  &__right {
    width: 35%;

    @media (max-width: 800px) {
      width: 100%;
    }

    & h5 {
      color: #74767b;
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    & div {
      & iframe {
        width: 100%;
      }
    }
  }
}
