.container {
  margin-top: 250px;

  @media (max-width: 1600px) {
    margin-top: 80px;
  }
  &__content {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    @media (max-width: 1060px) {
      flex-direction: column;
      align-items: center;
    }
  }
}
