.card {
  width: 100%;
  max-width: 350px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
}

.imgContainer {
  width: 100%;
  max-height: 300px;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 10px;
    transition: transform 0.3s;
  }
}

.fullname {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin: 10px 0;
}

.position {
  font-size: 18px;
  color: #777;
  margin: 10px 0;
}

.link {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  color: #ff4b4b;
  margin-top: auto;
  text-decoration: none;
  transition: color 0.3s;

  i {
    margin-left: 5px;
  }

  &:hover {
    color: #e33e3e;
  }
}
