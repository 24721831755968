.dropdown {
  position: relative;
  display: inline-block;
  margin-right: 25px;
}

.dropdown-toggle {
  width: 300px;
  border: 2px solid #74767b;
  color: #74767b;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;
}

.dropdown-menu {
  display: block;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 20;
  margin-top: 5px;
  border: none;
}

.dropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.dropdown-icon {
  transition: transform 0.4s ease;
}

.dropdown-icon.rotate {
  transform: rotate(180deg);
}

@media (max-width: 1610px) {
  .dropdown {
    width: 45%;
  }

  .dropdown-toggle {
    width: 100%;
  }
}

@media (max-width: 650px) {
  .dropdown {
    width: 100%;
    justify-content: center;
    display: flex;
  }

  .dropdown-toggle {
    margin-top: 5px;
  }
}

@media (max-width: 649px) {
  .dropdown-menu {
    left: 10px;
    bottom: -130px;
  }
}
