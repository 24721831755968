.container {
  position: relative;
  display: flex;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  justify-content: space-between;
  margin-top: 200px;
  margin-bottom: 100px;

  &__main {
    width: 75%;
  }

  &__menu {
    position: sticky;
    width: 23%;
    height: 100%;
    top: 200px;
    padding: 0 10px;
  }
}

.content {
  width: 100%;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;

    & h1 {
      color: #74767b;
    }

    & p {
      color: #74767b;
      font-size: 18px;
      display: flex;
      align-items: center;
      & svg {
        margin-right: 5px;
      }
    }
  }

  &__text {
    color: #74767b;
    font-size: 20px;
    font-weight: 400;
    text-align: justify;
    margin-top: 25px;
  }
}

@media (max-width: 1200px) {
  .container__menu {
    display: none;
  }
  .container__main {
    width: 100%;
  }
}

@media (max-width: 678px) {
  .content__top {
    & h1 {
      font-size: 1.5rem;
    }
  }
}
