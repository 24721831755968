.signbtn a {
  background: #da251c;
  border: 2px solid #da251c;
  width: 100%;
  line-height: 50px;
  border: none;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
  transition: 0.5s background;

  &:hover {
    background: none;
    color: #da251c;
    border: 1px solid #da251c;
  }
}

.membership {
  position: relative;
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 90px;

  &__left {
    &-card {
      width: 480px;
      border: 1px solid #74767b;
      border-radius: 10px;
      padding: 25px 0;

      &_top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & p {
          background: #da251c;
          padding: 5px 25px;
          font-size: 20px;
          text-align: start;
          font-weight: 500;
          color: #fff;
          border-radius: 0 0 100px 0;
        }

        & img {
          margin-right: 15px;
          width: 120px;
        }
      }

      &_bottom {
        margin-top: 50px;
        display: flex;
      }

      &_user {
        padding: 0 20px;
        margin-left: 15px;

        & svg {
          font-size: 100px;
        }
      }

      &_info {
        & p {
          color: #74767b;
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 5px;
        }
      }
    }
  }

  &__right {
    padding: 50px 0;

    & form {
      display: flex;
      flex-direction: column;
      align-items: center;

      & img {
        width: 220px;
      }

      & p {
        color: #74767b;
        font-size: 20px;
        margin: 20px 0;
        font-weight: bold;
      }

      & div {
        display: flex;
        width: 250px;
        padding: 5px;
        gap: 20px;
        flex-direction: column;

        & button,
        a {
          background: #da251c;
          border: 2px solid #da251c;
          width: 100%;
          line-height: 50px;
          border: none;
          font-size: 20px;
          color: #fff;
          cursor: pointer;
          border-radius: 5px;
          font-weight: bold;
          transition: 0.5s background;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background: none;
            color: #da251c;
            border: 1px solid #da251c;
          }
        }
      }
    }
  }
}

.position {
  position: absolute;
  right: 0;
  bottom: 20%;
  z-index: -10;
  opacity: 0.1;
  width: 500px;
  height: 500px;

  & img {
    width: 100%;
    height: 100%;
  }
}
