.party {
  background: #3474bc;
  padding: 30px 0;
  margin-top: -10px;

  &-content {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap; // Allows content to wrap on smaller screens

    @media (min-width: 1200px) {
      flex-direction: row; // Ensures items are in a row on larger screens
      justify-content: space-between;
      align-items: center;
    }
  }

  &-items {
    display: flex;
    align-items: center;
    color: #fff;
    padding: 10px 30px;
    border-radius: 8px;
    box-shadow: 0px 0px 34px 0px rgba(34, 60, 80, 0.3);
    white-space: nowrap;
    flex-shrink: 0;

    @media (min-width: 1200px) {
      margin-right: 30px; // Space between the items and the swiper
    }

    & p {
      white-space: nowrap;
    }

    & p:first-child {
      font-size: 70px;
    }

    & p:last-child {
      font-size: 20px;
      font-weight: bold;
    }
  }

  &-swiper {
    overflow-x: hidden;
    flex: 1; // Allows the swiper to take remaining space
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 10px;
    max-width: 300px;
    margin: auto;

    & p {
      white-space: nowrap;
    }

    & p:first-child {
      font-size: 70px;
    }

    & p:last-child {
      font-size: 20px;
      margin-top: -10px;
      margin-left: 10px;
      font-weight: bold;
      text-transform: capitalize;
    }
  }
}

@media (max-width: 992px) {
  .party-content {
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    white-space: normal;
  }

  .party-items {
    margin: 10px 0;
    flex-shrink: 0;
    width: 100%;
    text-align: center;
  }

  .party-swiper {
    display: flex;
    overflow-x: hidden;
    padding: 10px 0;
    width: 100%;
  }
}

@media (max-width: 678px) {
  .party-items p:first-child {
    font-size: 50px;
  }

  .party-items p:last-child {
    font-size: 16px;
  }

  .party-item p:first-child {
    font-size: 50px;
  }

  .party-item p:last-child {
    font-size: 16px;
    margin-left: 5px;
  }
}
