.react-tabs__tab {
  color: #74767b;
  font-size: 30px;
  font-weight: bold;
  border: 0;
  border-bottom: 3px solid #444;
  margin: 0px 10px;
}

.react-tabs__tab-list {
  display: flex;
  justify-content: center;
  border: 0;
}

.react-tabs__tab--selected {
  border-bottom: 3px solid #bb0000 !important;
}

.react-tabs__tab--selected span {
  color: #74767b !important;
}

.react-tabs__tab--selected:focus:after {
  border-bottom: 3px solid #bb0000 !important;
  outline: #bb0000;
  bottom: -3px !important;
}
