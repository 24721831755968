.card {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  width: 100%;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  background: #fff;

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }

  &__imgContainer {
    flex: 1;
    margin-right: 15px;
  }

  &__img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
  }

  &__details {
    flex: 2;
    display: flex;
    flex-direction: column;
  }

  &__name {
    color: #333;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
  }

  &__discount {
    color: #a9a9a9;
    font-size: 18px;
    text-decoration: line-through;
  }

  &__price {
    color: #333;
    font-size: 20px;
    font-weight: bold;
  }

  &__colorSizeContainer {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
  }

  &__colors {
    display: flex;
    gap: 8px;
  }

  &__sizes {
    display: flex;
    gap: 8px;
  }

  &__color {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    opacity: 0.5;
    border: 2px solid #000;
    cursor: pointer;
    transition: border 0.3s ease;
  }

  &__colorActive {
    opacity: 1;
    border-color: #da251c;
    border: 1px solid #000;
  }

  &__size {
    padding: 5px 10px;
    border: 1px solid #74767b;
    background: transparent;
    cursor: pointer;
    transition: background 0.3s ease;
  }

  &__sizeActive {
    background: #da251c;
    color: #fff;
    border-color: #da251c;
  }

  &__cart {
    background: #da251c;
    padding: 10px 20px;
    border: none;
    width: 100%;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    border-radius: 5px;
    align-self: flex-end;
    transition: background 0.3s ease;

    &:hover {
      background: #c8101d;
      color: #fff;
    }
  }

  &__cartSelect {
    background: #fff;
    border: 2px solid #da251c;
    color: #da251c;
  }
}
