.card {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 400px;

  &__title {
    color: #da251c;
    font-size: 22px;
    font-weight: 600;
  }

  &__line {
    width: 100px;
    height: 1px;
    background: #da251c;
    margin: 30px 0;
  }

  &__text {
    color: #74767b;
    font-size: 18px;
    line-height: 1.5;
    text-align: justify;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
  }

  &__link {
    text-decoration: none;
    font-size: 18px;
    color: #3474bc;
    font-weight: 600;
    transition: 0.5s;
    display: flex;
    align-items: center;
    gap: 5px;

    &:hover {
      color: #da251c;
    }
  }

  &__view {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #74767b;
    font-size: 18px;
  }
}
