.row {
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;
    margin-top: 10px;
    padding: 50px 0;
  }
  
  .container {
    margin-top: 250px;
  }
  
  .w-70 {
    flex: 0 0 70%;
    max-width: 70%;
  }
  
  .w-30 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  
  .d-flex {
    display: flex;
  }
  