.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
  margin-top: 50px;
}

.pagination li {
  margin: 0 5px;
}

.pagination li a {
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.pagination li a:hover {
  background-color: #007bff;
  color: white;
}

.pagination .active a {
  background-color: #007bff;
  border: 1px solid #ccc;
  color: white;
}

.pagination .disabled a {
  color: #ccc;
  pointer-events: none;
  cursor: default;
}
