* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
}

html,
body,
.App,
#root {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}

a {
  text-decoration: none;
}

footer {
  margin-top: auto;
}
