.dropdown {
  position: relative;
  display: inline-block;
  z-index: 20;
  width: 100px;
}

.dropdown-toggle {
  background-color: #c10b01;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown-menu {
  display: block;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 5px;
}

.dropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.dropdown-icon {
  transition: transform 0.4s ease;
}

.dropdown-icon.rotate {
  transform: rotate(180deg);
}
