section {
  position: relative;
  margin: 100px 0;
  margin-top: 30px;
  padding: 50px 0;
  z-index: -2;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    display: block;
    left: 0;
    width: 100%;
    z-index: -1;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
.location {
  font-size: 26px;
  color: #fff;
  display: flex;
  align-items: center;
  font-weight: 600;
}
.container {
  padding: 0;
  &__title {
    margin: 50px 0;
    color: #fff;
  }
}
.map {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__left {
    width: 45%;
    margin-top: 30px;
    & svg {
      width: 130%;
      height: 100%;
      & path {
        fill: #3474bc;
        &.active {
          fill: #da251c;
        }
        &:hover {
          fill: #f5625a;
          cursor: pointer;
        }
      }
    }
  }
  &__right {
    width: 45%;
    &-content {
      width: 100%;
      padding: 15px;
      background: #fbfbfb;
      border-radius: 10px;
      -webkit-box-shadow: 0px 9px 11px 5px rgba(34, 60, 80, 0.29);
      -moz-box-shadow: 0px 9px 11px 5px rgba(34, 60, 80, 0.29);
      box-shadow: 0px 9px 11px 5px rgba(34, 60, 80, 0.29);
      & h4 {
        margin-top: 20px;
      }
      &_top {
        display: flex;
        flex-direction: column;
        & p {
          color: #74767b;
          font-size: 18px;
          font-weight: bold;
        }
        & div {
          display: flex;
          margin-top: 10px;
          & img {
            width: 220px;
            height: auto;
            object-fit: contain;
          }
          & div {
            margin-left: 30px;
            display: flex;
            flex-direction: column;
            & p:first-child {
              font-weight: bold;
              color: #74767b;
              font-size: 20px;
            }
            & ul {
              display: flex;
              list-style: none;
              align-items: center;
              gap: 10px;
              margin-top: 10px;
              & li svg {
                color: #74767b;
                font-size: 26px;
              }
            }
            i & p {
              font-size: 20px;
              color: #74767b;
              font-weight: 500;
              margin-bottom: 7px;
            }
            & a {
              font-size: 20px;
              color: #74767b;
              font-weight: 500;
              text-decoration: none;
              margin-bottom: 7px;
            }
          }
        }
      }
    }
    &-items {
      margin-top: 10px;
      max-height: 400px;
      height: 100%;
      overflow-y: scroll;
      &_block {
        margin-top: 20px;
        display: flex;
        align-self: flex-start;
        & div:first-child {
          & img {
            width: 120px;
            height: 120px;
            object-fit: cover;
            margin-right: 5px;
          }
        }
        & div:last-child {
          & p:first-child {
            color: #74767b;
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 7px;
          }
          & p {
            color: #74767b;
            font-size: 18px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
@media (max-width: 1500px) {
  .map {
    flex-direction: column;
    &__left,
    &__right {
      width: 100%;
    }
    &__left {
      & svg {
        width: 100%;
      }
    }
  }
  .location {
    font-size: 18px;
    margin-top: 20px;
  }
}

@media (max-width: 700px) {
  .map__right {
    display: flex;
    justify-content: center;
  }
  .map__right-content_top {
    justify-content: center;
  }
  .map__right-content_top div {
    flex-direction: column;
    margin-left: 0;
  }
  .map__right-content_top div img {
    width: 100%;
    object-fit: cover;
    object-position: 70% 20%;
  }
  .map__right-content_top div div {
    margin-left: 0;
  }
  .map__right-content_top div div .bold-name:first-child,
  .map__right-content_top div div .bold-name,
  .map__right-content_top div div p {
    font-size: 18px;
  }
}
@media (max-width: 678px) {
  .map__left {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    & svg {
      max-width: 100%;
      width: 500px;
    }
  }
}
@media (max-width: 600px) {
  .map__left {
    width: 300px;
    display: flex;
    justify-content: center;
    & svg {
      max-width: 100%;
      width: 400px;
    }
  }
}
.flex-md-column {
  @media (max-width: 660px) {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
