body.grayscale {
  filter: grayscale(100%);
}

.navbar {
  width: 100% !important;
  position: fixed !important;
  z-index: 998 !important;
  top: 36px !important;
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2) !important;
  transition: top 0.3s ease !important;
}

.navbar.scrolled-navbar {
  top: 0 !important;
}

@media (max-width: 1600px) {
  .navbar {
    position: relative !important;
  }
}

.none-h50 {
  height: 50px;
}
