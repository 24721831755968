.title {
  position: relative;
  color: #74767b;
  text-transform: uppercase;
  font-size: 48px;
  display: flex;
  align-items: center;
  padding-left: 15px;

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 5px;
    left: 0;
    height: 55px;
    background: #da251c;
  }
}

@media (max-width: 800px) {
  .title {
    font-size: 35px;

    &::before {
      height: 40px;
    }
  }
}

@media (max-width: 786px) {
  .title {
    font-size: 30px;
  }
}

@media (max-width: 678px) {
  .title {
    font-size: 28px;

    &::before {
      height: 30px;
    }
  }
}
