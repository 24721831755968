.donate {
  display: flex;
  align-items: center;
  padding-bottom: 50px;

  &__form {
    max-width: 700px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__input {
    padding: 10px 20px;
    font-size: 18px;
    width: 100%;
    border: 3px solid #da251c;
    border-radius: 8px;
    color: gray;
    margin-bottom: 10px;

    &:focus {
      color: black;
      outline: none;
    }
  }

  &__button {
    background: #da251c;
    border: none;
    color: #fff;
    width: 100%;
    padding: 12px;
    height: 100%;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    border-radius: 8px;
  }
}

.modal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  & .btn {
    margin-top: 40px;
    font-size: 17px;
    font-weight: 600;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    background-color: #da251c;
    color: #fff;
    padding: 0 12px;
    cursor: pointer;
    border: 0;
    height: 48px;

    &:hover {
      background-color: #ff8983;
    }
  }
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 3rem;
  border-radius: 10px;
  border: 1px solid #888;
  width: 30%;

  & img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  & .form {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-sec {
      display: flex;
      gap: 20px;
      width: 100%;
    }

    &-pay {
      display: flex;
      flex-direction: column;
      width: 100%;

      & label {
        font-size: 14px;
        margin-bottom: 5px;
      }

      & input {
        padding: 10px;
        border-radius: 5px;
        outline: none !important;
        border: 0;
        background-color: #f2f7fa;
        font-size: 16px;
        width: 100%;

        &:focus {
          border: 2px solid #ff8983;
          outline: none !important;
        }
      }
    }
  }
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
