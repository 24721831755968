.subscribe {
  padding-bottom: 20px;

  &__form {
    max-width: 520px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
  }

  & div {
    margin-top: 10px;

    & p {
      color: #da251c;
      font-size: 18px;
    }
  }

  &__input {
    padding: 6px 10px;
    font-size: 16px;
    height: 100%;
    width: 70%;
    border: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px 0 0 5px;
    &:focus {
      border: 2px solid #da251c;
      outline: none !important;
    }
  }

  &__button {
    background: #da251c;
    border: none;
    color: #fff;
    padding: 15px;
    height: 100%;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    border-radius: 0 5px 5px 0;
  }
}

.subscribe p[data-testid="success-message"] {
  color: blue;
}
