.program {
  padding: 0;
  margin: 240px auto;

  &__title {
    margin-bottom: 50px;
  }

  &__subtitle {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #74767b;
    margin-bottom: 20px;
  }
  &__text {
    font-size: 18px;
    line-height: 1.5;
    color: #74767b;
    font-weight: 500;
    text-align: justify;
  }
}

.WordSection1 {
  text-align: justify;
}
