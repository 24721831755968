.active {
  display: flex;
  justify-content: center;

  &__container {
    padding-left: 0;
    padding-right: 0;
  }

  &__title {
    margin-bottom: 100px;
  }

  &-people__item {
    width: 100%;

    @media (max-width: 480px) {
      width: 100%;

      & img {
        width: 100%;
        object-fit: cover;
      }
    }

    & img {
      width: 100%;
      height: 400px;
      object-fit: cover;
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    }

    &:hover img {
      opacity: 0.3;
    }

    &:hover .active-people__info {
      display: flex;
      animation: fadeIn 0.3s forwards;
    }
  }

  &-people__info {
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(164, 211, 233, 0.16) 60%,
      rgba(52, 116, 188, 1) 100%
    );
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    display: none;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 0 20px 20px;
    transition: background 0.3s ease-in-out;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@media (max-width: 480px) {
  .active-people__inner {
    width: 100%;
  }
}
