.footer {
  background: #da251c;

  &__content {
    position: relative;

    &-item {
      width: 100%;
      padding: 50px 0;
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      z-index: 2;

      &_location {
        width: 420px;
      }
    }

    & div {
      z-index: 3;
    }

    & div:first-child {
      p {
        font-size: 18px;
      }
    }

    .position {
      position: absolute;
      right: 0;
      bottom: -60%;
      z-index: 0;
      opacity: 1;
      fill: #ffffff;
      width: 500px;
      height: 500px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__logo {
    img {
      width: 300px;
      margin-bottom: 20px;
    }
  }

  &__about {
    width: 300px;

    p:last-child {
      b {
        font-size: 22px;
      }

      margin-top: 20px;
    }
  }

  &__location {
    width: 250px;
    z-index: 3;
  }

  &__label {
    background: #ffff;
    color: #da251c;
    padding: 10px 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 25px;
  }

  &__text {
    color: #ffff;
    font-size: 18px;
    font-weight: 500;
  }

  &__social {
    display: flex;
    flex-direction: column;

    &-leader,
    &-party {
      p {
        color: #ffff;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      ul {
        display: flex;
        list-style: none;
        gap: 20px;

        li {
          margin: 0;

          a {
            cursor: pointer;
            width: 40px;
            height: 40px;
            background: #ffff;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #74767b;
            font-size: 20px;
            font-weight: bold;
          }
        }
      }
    }

    &-party {
      p {
        margin-top: 20px;
      }
    }

    a {
      color: #ffff;
      font-size: 18px;
      text-decoration: none;
      margin-top: 10px;
      font-weight: bold;
    }

    & .footer__contact {
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      & a {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}

@media (max-width: 1500px) {
  .footer__content {
    flex-wrap: wrap;
  }
}

@media (max-width: 1200px) {
  .footer__content {
    flex-wrap: wrap;
    align-items: center;
  }
}

@media (max-width: 992px) {
  .footer__content-item {
    flex-direction: column;
  }

  .footer__content {
    align-items: flex-start;
    gap: 30px;
  }
}

@media (max-width: 768px) {
  .footer__content-item {
    flex-direction: column;
  }

  .footer__about,
  .footer__location {
    width: 100%;
    margin-bottom: 20px;
  }

  .footer__logo img {
    width: 200px;
    margin-bottom: 20px;
  }

  .footer__label {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .footer__text {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .footer__social {
    flex-direction: column;

    &-leader,
    &-party {
      margin-bottom: 20px;
    }

    a {
      margin-top: 10px;
    }
  }
}

@media (max-width: 678px) {
  .footer__content {
    padding: 0 10px;
  }
}
