.news {
  &__content {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0;
    margin-top: 250px;
    margin-bottom: 100px;

    @media (max-width: 1600px) {
      margin-top: 100px;
    }
  }

  &__slider {
    max-height: 600px;
    height: 100%;
    object-fit: cover;
  }

  &__right {
    position: sticky;
    width: 23%;
    height: 100%;
    top: 200px;

    & .content {
      padding: 10px;
      margin-bottom: 30px;

      & .title {
        padding: 10px 0px;
        background: #da251c;
        text-transform: uppercase;
        color: #fff;
        font-size: 25px;
        font-weight: 600;
        text-align: center;
      }

      & .body {
        & .items {
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid #d0d0d0;
          padding: 20px 10px;

          & span {
            font-weight: bold;
            margin-top: 10px;
            font-size: 15px;
            color: #a80800;
          }

          & a {
            font-weight: 500;
            margin-bottom: 10px;
            margin-top: 10px;
            color: #2c2c2c;
            font-size: 18px;
          }

          &:hover {
            background-color: #f5f5f5;
          }
        }
      }

      & .body-part {
        & .items {
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid #d0d0d0;
          padding: 20px 10px;
          color: #2c2c2c;
          margin: 5px 0px;

          &:hover {
            background-color: #da251c;
            color: #fff;
          }
        }
      }
    }

    @media (max-width: 1280px) {
      display: none;
    }
  }

  &__title {
    color: #74767b;
    font-size: 30px;
    font-weight: bold;

    @media (max-width: 9920px) {
      font-size: 24px;
    }
  }

  &__left {
    width: 75%;
    @media (max-width: 1280px) {
      width: 100%;
    }
  }

  &__intro {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 992px) {
      flex-direction: column;
    }
  }

  &__items {
    display: flex;
    align-items: center;

    & p {
      width: 300px;
      color: #74767b;
      font-size: 22px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 10px;

      & svg {
        margin-right: 10px;
      }

      @media (max-width: 992px) {
        width: auto;
        margin-top: 20px;
      }
    }
  }

  &__imgs {
    object-fit: cover;
    width: 100%;
    margin: 60px 0;
  }

  &__text {
    color: #74767b;
    font-size: 18px;
    text-align: justify;
    line-height: 1.5;
    font-weight: 500;
  }

  &__list {
    list-style: none;
    background: #d9d9d9;

    &-item {
      display: flex;
      align-items: center;
      padding: 20px 0;
      font-size: 20px;
      color: #74767b;
      transition: 0.5s;
      cursor: pointer;

      & span {
        margin: 0 20px;
      }

      &:hover {
        background-color: #3474bc;
        color: #fff;
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    & ul {
      display: flex;
      list-style: none;
      gap: 20px;

      & li svg {
        color: #74767b;
        font-size: 26px;
        cursor: pointer;
      }
    }

    & p {
      color: #74767b;
      font-size: 20px;
      font-weight: 500;
      display: flex;
      align-items: center;

      & svg {
        margin-right: 10px;
      }
    }
  }
}
