.store {
  padding: 0;
  margin-top: 200px;
  margin-bottom: 100px;

  & div {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
  }
}
