.projects {
  background: linear-gradient(90deg, #171c4e, #03afff);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 20px;
  cursor: pointer;
  margin-top: 30px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .projects__text {
      color: #fff;
      font-size: 48px;
      font-weight: bold;
      transition: 0.4s transform linear;

      &:hover {
        transform: scale(1.1);
      }
    }

    & div {
      width: 300px;
      height: 250px;

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__logo {
    display: flex;
    align-items: center;
  }
}

.projects__text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

@keyframes breathing {
  0% {
    transform: scale(1) rotate(0);
  }

  25% {
    transform: scale(1.15) rotate(1deg);
  }

  60% {
    transform: scale(1.1);
  }

  to {
    transform: scale(1) rotate(0);
  }
}

@media (max-width: 786px) {
  .projects {
    padding: 45px;
  }

  .projects__item div {
    display: none;
  }
}

@media (max-width: 678px) {
  .projects {
    margin-bottom: 30px;
  }
  .projects__item .projects__text {
    font-size: 34px;
  }
}
