.header-top {
  background: #f5f5f5;
  padding: 7px 0;

  &__content {
    color: #da251c;
    padding: 0;
  }

  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & p {
      text-wrap: nowrap !important;
    }

    & a {
      text-wrap: nowrap !important;
    }
  }

  &__link {
    color: #da251c;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 18px;

    & svg {
      margin-right: 5px;
    }
  }
}

@media (max-width: 800px) {
  .header-top {
    display: none;
  }
}
