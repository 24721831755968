.container {
  padding: 0;
  margin-top: 100px;
}

.form {
  margin: 100px 0;
  background: #f5f5f5;
  padding: 50px;
  width: 80%;
  display: flex;
  flex-direction: column;
  @media (max-width: 800px) {
    width: 100%;
  }

  &__input {
    width: 60%;
    background: none;
    border: 1px solid #74767b;
    padding: 15px;
    border-radius: 6px;
    margin-top: 15px;
    font-size: 18px;
    color: #74767b;
    @media (max-width: 500px) {
      width: 100%;
    }

    &::placeholder {
      color: #74767b;
      font-size: 18px;
    }
  }

  &__textarea {
    width: 60%;
    height: 200px;
    background: none;
    border: 1px solid #74767b;
    padding: 15px;
    border-radius: 6px;
    margin-top: 15px;
    font-size: 18px;
    resize: none;
    color: #74767b;
    margin-bottom: 50px;
    @media (max-width: 500px) {
      width: 100%;
    }

    &::placeholder {
      color: #74767b;
      font-size: 18px;
    }
  }

  &__btn {
    width: 40%;
    background: #da251c;
    border: none;
    border-radius: 6px;
    padding: 15px;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    color: #f5f5f5;
    cursor: pointer;
    margin-top: 15px;

    &:hover {
      background: #a71c15;
    }
  }
}
