.container {
  margin: 250px auto;

  @media (max-width: 1600px) {
    margin: 100px auto;
  }

  &__btn {
    margin: 50px 0;
    background: #e14038;
    border: none;
    line-height: 50px;
    color: #fff;
    padding: 0 25px;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
    border-radius: 5px;

    &:first-child {
      margin-right: 50px;
    }
  }
}

.row {
  display: flex;
  gap: 10px;
}

.w-70 {
  width: 70%;
  @media (max-width: 1200px) {
    width: 100%;
  }
}

.w-30 {
  width: 30%;

  @media (max-width: 1200px) {
    display: none;
  }
}

.menu {
  & .title {
    padding: 10px;
    text-align: center;
    background-color: #c10b01;
    font-size: 25px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    border-radius: 5px;
  }

  & .item {
    & .items {
      display: flex;
      flex-direction: column;
      font-size: 20px;
      font-weight: 500;
      padding: 15px 10px;
      border-bottom: 1px solid #ababab;
      color: #494949;
      border-radius: 5px;
      margin: 10px 0px;

      &:hover {
        background-color: #e14038;
        color: #fff;
      }
    }
  }
}

.d-flex {
  display: flex;
}

.mb-3 {
  margin-bottom: 3rem;
}
