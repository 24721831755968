.card {
  max-width: 500px;
  width: 100%;
  display: flex;
  max-height: 350px;
  height: 100%;
  margin-bottom: 50px;
  flex-direction: column;

  @media (max-width: 1060px) {
    width: 100%;
  }

  &__title {
    color: #da251c;
    font-size: 24px;
    height: 60px;
    font-weight: bold;
  }

  &__line {
    width: 200px;
    background: #da251c;
    height: 1px;
    display: block;
    margin: 15px 0;
    text-align: justify;
  }

  & span {
    text-align: right;
    font-size: 18px;
    margin-top: 10px;
  }

  &__text {
    margin-top: 25px;
    color: #74767b;
    font-size: 18px;
    text-align: justify;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
  }

  &__link {
    color: #3474bc;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: 0.5s;

    @media (max-width: 1000px) {
      font-size: 14px;
    }

    @media (max-width: 650px) {
      font-size: 16px;
    }

    &:hover {
      color: #da251c;
    }
  }

  &__view,
  &__link {
    display: flex;
    align-items: center;

    & svg {
      margin-right: 8px;
      margin: 0;
      padding: 0;
    }
  }
}

@media (max-width: 678px) {
  .card {
    width: 100%;
    margin-bottom: 50px;
  }

  .card_bottom {
    margin-top: 10px;
  }
}

@media (max-width: 490px) {
  .card {
    width: 100%;
    margin-bottom: 100px;
  }
}
